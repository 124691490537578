import { useCallback, useRef, type TransitionEvent } from 'react';

import * as Styled from './BottomModal.styled';

interface BottomModalProps {
  show?: boolean;
  onClose: () => void;
  onCloseComplete?: () => void;
  backdropClosesModal?: boolean;
  showHeader?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export function BottomModal({
  show = false,
  onClose,
  onCloseComplete = () => {},
  backdropClosesModal = true,
  showHeader = true,
  children,
}: BottomModalProps) {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleCloseTransitionEnd = useCallback(
    (e: TransitionEvent) => {
      if (!show && e.currentTarget === contentRef.current) {
        onCloseComplete();
      }
    },
    [show, onCloseComplete],
  );

  return (
    <Styled.View show={show} onTransitionEndCapture={handleCloseTransitionEnd} ref={contentRef}>
      <Styled.ClickAway onClick={backdropClosesModal ? onClose : undefined} />
      <Styled.Content show={show}>
        {showHeader && (
          <Styled.Header>
            <Styled.CloseButton onClick={onClose}>
              <Styled.CloseIcon />
            </Styled.CloseButton>
          </Styled.Header>
        )}
        <Styled.Scroller withHeader={showHeader}>{children}</Styled.Scroller>
      </Styled.Content>
    </Styled.View>
  );
}
