import { useEffect, useState } from 'react';

import { PaymentService } from 'apps/pay/services';
import { heapAddEventProperties } from 'utils/heap';

import { type Visit } from 'types/api';

type VisitResponseMap = { [key: string]: Visit };

const visitResponseMap: VisitResponseMap = {};

export function useVisitDetails({ visitCode }: { visitCode: string }) {
  const [visit, setVisit] = useState<Visit | null>(() => visitResponseMap[visitCode] ?? null);

  useEffect(() => {
    async function fetchVisitInfo() {
      const { success, data } = await PaymentService.getVisitDetails({ visitCode });
      if (success) {
        setVisit(data);
        visitResponseMap[visitCode] = data;

        // Add site info to heap events for memberless payment
        heapAddEventProperties({
          transactionId: data.id,
          siteId: data.site?.id,
          siteName: data.site?.name,
        });
      }
    }
    fetchVisitInfo();
  }, [visitCode]);

  return visit;
}
