import { FontDMSans } from 'assets/fonts/dm-sans/FontDMSans';
import { FontTTHoves } from 'assets/fonts/tt-hoves/FontTTHoves';
import Providers from 'providers';
import { setDefaultAuthMethod } from 'utils/http';

import Pay from './Pay';
import { GlobalStyles } from './Pay.styled';

import { CONNECT_TO_EXTERNAL_API } from 'constants/FeatureFlags';

if (CONNECT_TO_EXTERNAL_API) setDefaultAuthMethod('token');

export function PayApp() {
  return (
    <Providers>
      <Pay />
      <FontTTHoves />
      <FontDMSans />
      <GlobalStyles />
    </Providers>
  );
}
