export enum TimeOfDay {
  AM = 'AM',
  PM = 'PM',
}

// TODO: think luxon uses L's. we should clean this up to make sure it's working
export const DATE_FORMAT = 'M/dd/yyyy';

export const DATE_FORMAT2 = 'LL/dd/yyyy';

export const DATE_FORMAT_ISO_8601 = 'yyyy-LL-dd';

export const DATE_TIME_FORMAT = 'M/dd/yy h:mm a';

export const FULL_MONTH_DATE_TIME_FORMAT = 'MMMM dd, h:mm a';

export const TIME_FORMAT_24 = 'HH:mm'; // 1:23 PM

export const TIME_FORMAT = 'h:mm a'; // 1:23 PM

export const TIME_DURATION_FORMAT = 'h:mm'; // 1:23

export const SHORT_MONTH_DAY = 'LLL d'; // 'Feb 10'

export const MONTH_DAY = 'MMMM d'; // 'February 10'

export const MONTH_YEAR = 'MMMM yyyy'; // "February 2022"

export const MONTH_DAY_YEAR = 'MMMM d, yyyy'; // "February  10, 2022"

export const MONTH_DAY_HOURS_MINUTES = 'MMMM d, h:mm a';

export const SHORT_MONTH_DAY_YEAR = 'MMM d, yyyy'; // "Feb 10, 2022"

export const SHORT_MONTH_DAY_YEAR_HOURS_MINUTES = 'MMM d h:mm a'; // "Feb 10, 1:23 PM"

export const DOW_MONTH_DAY_YEAR = 'EEE, MMM d, yyyy'; // "Thu, Feb 10, 2022"

export const DOW_FULL_MONTH_DAY_YEAR = 'EEE, LLLL d, yyyy'; // "Thu, February 10, 2022"

export const FULL_DOW_MONTH_DAY_YEAR = 'EEEE, LLLL d, yyyy'; // "Thu, February 10, 2022"

export const PICKER_DATE_TIME_FORMAT = 'E, LLL d, y p'; // Thu, Feb 17, 2022 12:00 AM'

export const PICKER_DAY_FORMAT = 'E, LLL d'; // Thu, Feb 17

export const PICKER_DAY_FORMAT_LUXON = 'EEE LLL d'; // Thu, Feb 17

export const PAST_DUE_VISIT_FORMAT = 'E LLL d';

export const FULL_WEEK_DAY_NAMES = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const ONE_MINUTE_IN_MILLISECONDS = 60 * 1000;
export const ONE_HOUR_IN_MILLISECONDS = 60 * ONE_MINUTE_IN_MILLISECONDS;
export const ONE_DAY_IN_MILLISECONDS = 24 * ONE_HOUR_IN_MILLISECONDS;
export const ONE_HOUR_IN_SECONDS = 60 * 60;
export const ONE_DAY_IN_SECONDS = 24 * ONE_HOUR_IN_SECONDS;
