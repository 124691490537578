import styled from 'styled-components';

import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const Box = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 1.2;
  font-weight: 600;
  ${withStyledSystem}
`;

export const Content = styled.div<StyledSystemProps>`
  display: block;
  padding: 0 24px;
  flex: none;
  ${withStyledSystem}
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #e6e6e6;
`;
