import { createGlobalStyle } from 'styled-components';

import TTHovesBoldTtf from './TTHoves-Bold.ttf';
import TTHovesBoldWoff from './TTHoves-Bold.woff';
import TTHovesBoldWoff2 from './TTHoves-Bold.woff2';
import TTHovesDemiBoldTtf from './TTHoves-DemiBold.ttf';
import TTHovesDemiBoldWoff from './TTHoves-DemiBold.woff';
import TTHovesDemiBoldWoff2 from './TTHoves-DemiBold.woff2';
import TTHovesMediumTtf from './TTHoves-Medium.ttf';
import TTHovesMediumWoff from './TTHoves-Medium.woff';
import TTHovesMediumWoff2 from './TTHoves-Medium.woff2';
import TTHovesRegularTtf from './TTHoves-Regular.ttf';
import TTHovesRegularWoff from './TTHoves-Regular.woff';
import TTHovesRegularWoff2 from './TTHoves-Regular.woff2';

export const FontTTHoves = createGlobalStyle`
  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesBoldWoff2}) format('woff2'),
      url(${TTHovesBoldWoff}) format('woff'),
      url(${TTHovesBoldTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesDemiBoldWoff2}) format('woff2'),
      url(${TTHovesDemiBoldWoff}) format('woff'),
      url(${TTHovesDemiBoldTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesMediumWoff2}) format('woff2'),
      url(${TTHovesMediumWoff}) format('woff'),
      url(${TTHovesMediumTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'TT Hoves';
    src:
      url(${TTHovesRegularWoff2}) format('woff2'),
      url(${TTHovesRegularWoff}) format('woff'),
      url(${TTHovesRegularTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`;
