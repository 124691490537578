import { lazy, Suspense } from 'react';

import { Route, Switch } from 'react-router-dom';

import { LogoBar, Page } from 'apps/pay/components';
import VisitLandingPage from 'apps/pay/screens/VisitLandingPage';
import { ExternalRedirect } from 'components/ExternalRedirect';
import { getCustomerAppUrl } from 'utils/getCustomerAppUrl';

const VisitCompletePage = lazy(() => import('apps/pay/screens/VisitCompletePage'));
const VisitReceiptPage = lazy(() => import('apps/pay/screens/VisitReceiptPage'));

export default function Pay() {
  return (
    <Page paddingBottom="24px">
      <LogoBar />
      <Switch>
        <Route exact path="/">
          <ExternalRedirect to={getCustomerAppUrl()} />
        </Route>
        <Route exact path="/:visitCode">
          <VisitLandingPage />
        </Route>
        {/* Exit route if payment completed */}
        <Route exact path="/:visitCode/complete">
          <Suspense fallback={null}>
            <VisitCompletePage />
          </Suspense>
        </Route>
        {/* Exit route if "not my vehicle" */}
        <Route exact path="/:visitCode/drive-out">
          <Suspense fallback={null}>
            <VisitCompletePage hasReceipt={false} />
          </Suspense>
        </Route>
        <Route exact path="/:visitCode/receipt">
          <Suspense fallback={null}>
            <VisitReceiptPage />
          </Suspense>
        </Route>
      </Switch>
    </Page>
  );
}
