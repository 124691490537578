/**
 * @return - Dollar format, e.g. $2, $2.50
 *           return null if number is NaN
 */
export function convertNumberToDollarFormat({
  price,
  includeZeros = false,
  includeCommas = false,
}: {
  price?: number | string | null;
  includeZeros?: boolean;
  includeCommas?: boolean;
}) {
  const formattedNumber = Number(price);
  if (price === null || Number.isNaN(formattedNumber)) {
    return null;
  }
  let toFixedDollar = `$${formattedNumber?.toFixed(2)}`;
  if (includeCommas) {
    toFixedDollar = toFixedDollar.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  if (includeZeros) {
    return toFixedDollar;
  }

  return toFixedDollar.replace('.00', '');
}

export function convertDollarFormatToNumber(val?: string | null) {
  if (!val) {
    return NaN;
  }
  return Number(val.replace(/[^\d.]/g, ''));
}
