import styled from 'styled-components';

import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const PageBox = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  flex-shrink: 0;
  ${withStyledSystem}
`;

export const PageSection = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 24px;
  flex-shrink: 0;
`;
