import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { normalizeButton } from 'styled/mixins';
import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const baseButtonCss = css`
  ${normalizeButton}
  width: 100%;
  height: 54px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.01em;
  border-radius: 4px;
  text-decoration: none;

  &:active {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  }
`;

export const primaryButtonCss = css`
  background-color: ${({ theme }) => theme.colors.blue6};
  color: #fff;
`;

export const secondaryButtonCss = css`
  border: 1px solid ${({ theme }) => transparentize(0.675, theme.colors.blue6)};
  color: ${({ theme }) => theme.colors.blue6};
`;

export const tertiaryButtonCss = css`
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.8);
`;

export const StyledButton = styled.button<StyledSystemProps>`
  ${baseButtonCss}
  ${withStyledSystem}
`;
StyledButton.defaultProps = {
  type: 'button',
};
