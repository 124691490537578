import styled from 'styled-components';

import { ReactComponent as ChevronRight } from 'assets/svg/chevron-right.svg';
import { normalizeButton } from 'styled/mixins';
import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const View = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  ${withStyledSystem}
`;

export const Overview = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  height: 100px;
  background-color: rgba(246, 246, 246, 0.8);
  padding-left: 24px;
  padding-right: 24px;
  flex-shrink: 0;
  backdrop-filter: blur(8px);
`;

export const OverviewContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;
`;

export const TotalPrice = styled.div<StyledSystemProps>`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: -0.01em;
  color: #000;
  ${withStyledSystem}
`;

export const OverviewDetails = styled.div`
  height: 21px;
  align-self: stretch;
  position: relative;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  color: #8e8e8e;
`;

export const OverviewDescription = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.9)})
    translate3d(0, ${({ isVisible }) => (isVisible ? '0' : '10px')}, 0);
  transition:
    opacity 200ms linear ${({ isVisible }) => (isVisible ? 150 : 0)}ms,
    transform 250ms ease ${({ isVisible }) => (isVisible ? 150 : 0)}ms;
`;

export const OverviewData = styled(OverviewDescription)<{ isVisible: boolean }>`
  transform: scale(${({ isVisible }) => (isVisible ? 1 : 0.9)})
    translate3d(0, ${({ isVisible }) => (isVisible ? '0' : '-10px')}, 0);
`;

export const DetailButtonLabel = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 22px;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: inherit;
  transform: scale3d(
    ${({ isVisible }) => (isVisible ? 1 : 0)},
    ${({ isVisible }) => (isVisible ? 1 : 0)},
    1
  );
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition:
    opacity 250ms linear,
    transform 200ms ease;
  transition-delay: ${({ isVisible }) => (isVisible ? '250ms,200ms' : '0ms,100ms')};
`;

export const Chevron = styled(ChevronRight)<{
  isExpanded: boolean;
}>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -12px;
  fill: currentColor;
  transform: rotateZ(${({ isExpanded }) => (isExpanded ? '270deg' : '90deg')});
  transition: transform 250ms linear 150ms;
`;

export const DetailButton = styled.button<{ isExpanded: boolean }>`
  position: relative;
  display: block;
  width: ${({ isExpanded }) => (isExpanded ? 84 : 98)}px;
  height: 40px;
  border-radius: 32px;
  border: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-drag: none;
  user-select: none;
  transition: width 500ms ease;

  &:active {
    color: rgba(0, 0, 0, 0.65);
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    box-shadow:
      inset 0 1px 6px rgba(0, 0, 0, 0.04),
      0 1px 0 #fff;

    & ${Chevron} {
      opacity: 0.75;
    }
  }
`;
DetailButton.defaultProps = {
  type: 'button',
};

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 10px 24px;
  flex-shrink: 0;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const Details = styled.div<{ isExpanded: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  overflow: hidden;

  max-height: ${({ isExpanded }) => (isExpanded ? 700 : 0)}px;
  transition: max-height ${({ isExpanded }) => (isExpanded ? 500 : 400)}ms ease-in-out;

  & > ${DetailContent} {
    opacity: ${({ isExpanded }) => (isExpanded ? 1 : 0)};
    transform: translate3d(0, ${({ isExpanded }) => (isExpanded ? '0%' : '-65%')}, 0)
      scale3d(1, ${({ isExpanded }) => (isExpanded ? 1 : 0.75)}, 1);
    transition:
      opacity ${({ isExpanded }) => (isExpanded ? 400 : 300)}ms linear
        ${({ isExpanded }) => (isExpanded ? 200 : 0)}ms,
      transform 400ms ease-in-out ${({ isExpanded }) => (isExpanded ? -50 : 50)}ms;
  }
`;

export const VehicleConfirmButton = styled.button`
  ${normalizeButton}
  display: inline-block;
  padding: 4px;
  margin: -4px;
  border-radius: 4px;
  color: #007aff;
  text-decoration: underline;

  &:active {
    background-color: rgba(0, 0, 0, 0.15);
  }
`;
VehicleConfirmButton.defaultProps = {
  type: 'button',
};

export const FeeInfoButton = styled.button`
  ${normalizeButton}
  vertical-align: top;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: -12px 0;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 8px;

  & svg {
    display: block;
    width: 22px;
    height: 22px;
    fill: currentColor;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    border: 4px solid #fff;
  }
`;
FeeInfoButton.defaultProps = {
  type: 'button',
};
