import styled from 'styled-components';

import { fontTTHoves } from 'styled/mixins';
import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const Box = styled.div<StyledSystemProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #000;
  flex-shrink: 1;
  ${withStyledSystem}
`;

export const LineRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
  min-height: 20px;
`;

export const LineColumn = styled.div<StyledSystemProps>`
  ${fontTTHoves}
  display: block;
  flex: none;
  min-height: 20px;
  ${withStyledSystem}
`;
