import { useLayoutEffect } from 'react';
import type { ReactNode } from 'react';

export interface ExternalRedirectProps {
  to: string;
  delay?: number;
  children?: ReactNode;
}
export function ExternalRedirect({ to, delay, children }: ExternalRedirectProps) {
  useLayoutEffect(() => {
    if (typeof delay === 'number') {
      const timeout = setTimeout(() => {
        window.location.href = to;
      }, delay);
      return () => clearTimeout(timeout);
    }

    window.location.href = to;
    return () => {};
  }, [to, delay]);

  return children ? (
    // parent is jsx. this prevents an error. look into fixing this issue lint error later
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : null;
}
