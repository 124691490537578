import styled, { keyframes } from 'styled-components';

import { fadeIn, slideUpFadeIn } from 'styled/keyframes';
import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const View = styled.div<StyledSystemProps>`
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: ${fadeIn} 300ms ease 50ms 1 backwards;
  ${withStyledSystem}
`;

const iconBoxReveal = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
`;

const iconReveal = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(55,209,93,0.35),
                inset 0 0 0 40px rgba(55,209,93,0.3);
  }
  100% {
    box-shadow: 0 0 0 600px rgba(55,209,93,0),
                inset 0 0 0 0 rgba(55,209,93,0);
  }
`;

export const IconBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  animation: ${iconBoxReveal} 750ms ease 250ms 1 backwards;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    animation: ${iconReveal} 1000ms linear 0ms 1 backwards;
  }
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 32px;
  animation: ${slideUpFadeIn} 750ms ease 750ms 1 backwards;
`;

export const Message = styled.div`
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #020202;
  line-height: 1;
`;

export const Description = styled.div`
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;

  & > p {
    margin-top: 4px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
  }
`;
