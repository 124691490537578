import styled from 'styled-components';

import { normalizeButton } from 'styled/mixins';
import { withStyledSystem } from 'theme';

import type { StyledSystemProps } from 'theme';

export const Heading = styled.div<StyledSystemProps>`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.01em;
  line-height: 1.2;
  color: #000;
  margin-bottom: 8px;
  ${withStyledSystem}
`;

export const FeeInfoButton = styled.button`
  ${normalizeButton}
  vertical-align: top;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin: -12px 0;
  color: rgba(0, 0, 0, 0.54);
  border-radius: 8px;

  & svg {
    display: block;
    width: 22px;
    height: 22px;
    fill: currentColor;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.1);
    border: 4px solid #fff;
  }
`;
FeeInfoButton.defaultProps = {
  type: 'button',
};

export const HelpLink = styled.a<StyledSystemProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% + 16px);
  height: 52px;
  flex-shrink: 0;
  text-decoration: none;
  border-radius: 8px;
  padding: 0 8px;
  margin: 2px -8px;
  color: #000;

  & svg {
    fill: currentColor;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.07);
    color: ${({ theme }) => theme.colors.blue7};
  }
  ${withStyledSystem}
`;
