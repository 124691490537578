import { createGlobalStyle } from 'styled-components';

import DMSansBoldTtf from './DMSans-Bold.ttf';
import DMSansBoldWoff from './DMSans-Bold.woff';
import DMSansBoldWoff2 from './DMSans-Bold.woff2';
import DMSansMediumTtf from './DMSans-Medium.ttf';
import DMSansMediumWoff from './DMSans-Medium.woff';
import DMSansMediumWoff2 from './DMSans-Medium.woff2';
import DMSansRegularTtf from './DMSans-Regular.ttf';
import DMSansRegularWoff from './DMSans-Regular.woff';
import DMSansRegularWoff2 from './DMSans-Regular.woff2';

export const FontDMSans = createGlobalStyle`
  @font-face {
    font-family: 'DM Sans';
    src:
      url(${DMSansBoldWoff2}) format('woff2'),
      url(${DMSansBoldWoff}) format('woff'),
      url(${DMSansBoldTtf}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src:
      url(${DMSansMediumWoff2}) format('woff2'),
      url(${DMSansMediumWoff}) format('woff'),
      url(${DMSansMediumTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'DM Sans';
    src:
      url(${DMSansRegularWoff2}) format('woff2'),
      url(${DMSansRegularWoff}) format('woff'),
      url(${DMSansRegularTtf}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`;
