import { LoadingIcon } from './LoadingSpinner.styled';

type LoadingSpinnerProps = {
  size?: number;
  color?: string;
  ringOpacity?: number;
  className?: string;
};

export default function LoadingSpinner({
  size = 24,
  color = '#949494',
  ringOpacity = 0.175,
  className,
}: LoadingSpinnerProps) {
  return (
    <LoadingIcon
      data-testid="loading"
      size={size}
      color={color}
      ringOpacity={ringOpacity}
      className={className}
    />
  );
}
