import { ReactComponent as LogoTagline } from 'assets/svg/m-logomark-tagline.svg';
import { Box } from 'components/uikit';
import { getCustomerAppUrl } from 'utils/getCustomerAppUrl';

import { Text } from '../Text';

interface NextLevelParkingProps {
  onClickSignup: () => void;
}

export function NextLevelParking({ onClickSignup }: NextLevelParkingProps) {
  return (
    <>
      <Box flexDirection="column" justifyContent="flex-start" alignItems="center" width="100%">
        <LogoTagline />
      </Box>
      <Text
        fontSize="22px"
        lineHeight="26px"
        textAlign="center"
        margin="0 24px"
        paddingTop="16px"
        alignSelf="center"
      >
        Sign up once and never wait in line again.
      </Text>
      <a
        href={getCustomerAppUrl()}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickSignup}
        css={`
          display: flex;
          font-size: 14px;
          line-height: 16px;
          color: #007aff;
          text-align: center;
          width: auto;
          align-self: center;
          padding: 8px;
          margin: 8px 0;
        `}
      >
        <Text>Just drive in and drive out.</Text>
      </a>
    </>
  );
}
