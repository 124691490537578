import ReactDOM from 'react-dom';

import { PayApp } from 'apps/pay';
import { setCurrentAppName } from 'utils/currentApp';
import { initDatadog } from 'utils/datadog';

import * as serviceWorker from './serviceWorker';

setCurrentAppName('pay');
initDatadog('pay');

ReactDOM.render(<PayApp />, document.getElementById('root'));

document.getElementById('initial-loader')?.remove();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
