export const fontStackSystemUi =
  'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif';

export const fontStackNeueMontreal = `"Neue Montreal", ${fontStackSystemUi}`;

export const fontStackTTHoves = `"TT Hoves", ${fontStackSystemUi}`;

export const fontStackDMSans = `"DM Sans", ${fontStackSystemUi}`;

export const fontStackMonospace = 'Menlo, Monaco, Consolas, monospace';
